const HiraganaName = {
    A  : 'あ', I  : 'い', U  : 'う', E  : 'え', O  : 'お',
    KA : 'か', KI : 'き', KU : 'く', KE : 'け', KO : 'こ',
    SA : 'さ', SI : 'し', SU : 'す', SE : 'せ', SO : 'そ',
    TA : 'た', TI : 'ち', TU : 'つ', TE : 'て', TO : 'と',
    NA : 'な', NI : 'に', NU : 'ぬ', NE : 'ね', NO : 'の',
    HA : 'は', HI : 'ひ', HU : 'ふ', HE : 'へ', HO : 'ほ',
    MA : 'ま', MI : 'み', MU : 'む', ME : 'め', MO : 'も',
    YA : 'や', YI : '-',  YU : 'ゆ', YE : '-',  YO : 'よ',
    RA : 'ら', RI : 'り', RU : 'る', RE : 'れ', RO : 'ろ',
    WA : 'わ', WI : '-',  WU : '-',  WE : '-',  WO : 'を',
    NN : 'ん', N2 : '-',  N3 : '-',  N4 : '-',  N5 : '-', 
};

export default HiraganaName
