import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// https://material-ui.com/customization/color/
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

const styles = theme => ({
    card: {
        maxWidth: 400,
    },
    media: {
        width: 128,
        height: 128,
        margin: "auto",
        marginTop: "0%",
        marginBottom: "5%",
        // paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatarCorrect: {
        backgroundColor: green['A400'],
    },
    avatarInCorrect: {
        backgroundColor: grey[500],
    },
});

class RecipeReviewCard extends React.Component {


    constructor(props) {
        super(props);
        console.log("this.props:", this.props);
        this.state = {
            expanded: false,
        };
    }
        
    render() {
        const { classes } = this.props;

        let myTitle = "もんだい「" + this.props.targetQ + "」";
        let myScore = "とくてん「" + this.props.score + "」";

        let myColorClass = classes.avatarInCorrect;
        if(this.props.score >= 9999){
            myColorClass = classes.avatarCorrect;;
        }

        return (
                <Card className={classes.card}>

                <CardHeader
		            avatar={
        	                <Avatar aria-label="Recipe" className={myColorClass}>
                            	{this.props.qIdx}
                        	</Avatar>
          			}
		    		title = {myTitle}
		          	subheader = {myScore}
        		/>

                <CardMedia
            		className={classes.media}
            		image={this.props.myImg}
            		title="Paella dish"
        		/>

                </Card>
    );
  }
}

RecipeReviewCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RecipeReviewCard);
