import React from "react";
import ReactDOM from 'react-dom';
import  KatakanaName from './KatakanaDict'
// import appare from './dojo_appare.jpg'
import dojo from './dojo2.png'
import dojo_close from './dojo2_close.png'
import dojo_appare from './dojo2_appare.png'

const MyProgress = () => {
    return <p>Now in Progress...</p>;
};

// 判定スコア("%"で表示するバージョン、小数点の概念は難しいか)
// class JudgeScore extends React.Component {

//     render(){

//         let scoreArr = this.props.score;
//         scoreArr.forEach(function(v, i, a){
//             let score = Math.round(scoreArr[i]['probability'] * 100 * 1000) / 1000
//             console.log("TOP ", i, ":", KatakanaName[scoreArr[i]['label']], score, "%");
//         });
//         return(
//                 <div className="info_wrapper">
//                 {scoreArr.map((item, i) => (
//                         <div key={i}>
//                         <p>TOP {i+1} {KatakanaName[item['label']]} {Math.round(item['probability'] * 100 * 10000) / 10000}%</p>
//                         </div>
//                 ))}
//             	</div>
//         );
//     }
// }

function calcScore(val){
    return Math.round(val * 100 * 100)
}

// 数値を前スペース埋めした文字列を返す(最大5桁)
function paddingScore(val){

    const len = 5 - String(val).length;
    for(let i = 0; i < len; i++){
        val = '\u00a0' + val;
    }
    // console.log('val:', val);
    return val;
}


// 判定スコア(10000点満点で表示するパターン、四捨五入、0.99995以上は満点扱い)
class JudgeScore extends React.Component {

    render(){

        const top_N = 3;
        let scoreArr = this.props.score;
        scoreArr = scoreArr.slice(0, top_N);
        scoreArr.forEach(function(v, i, a){
            let score = calcScore(scoreArr[i]['probability']);
            // console.log("TOP ", i, ":", KatakanaName[scoreArr[i]['label']], score, "てん");
        });
        return(
                <div className="info_wrapper">
                {scoreArr.map((item, i) => (
                        <div key={i}>
                        <p className="score_info_elem">はんてい({i+1}) 「{KatakanaName[item['label']]}」 {paddingScore(Math.round(item['probability'] * 100 * 100))} [てん]</p>
                        </div>
                ))}
            	</div>
        );
    }
}


// 描画イメージ
class MyImg extends React.Component {
    render(){
        return(
                <div className="dispWrap">
                <img className="dispImg" src={this.props.imgSrc}/>
                </div>
        );
    }
}

// 現在のもんだい
class CurQ extends React.Component {
    render(){
        return(
                <div className="info_wrapper">
                <p>もんだい「{this.props.curQ}」</p>            
                </div>
        );
    }
}

// 「つぎのもんだい」
class NextBtn extends React.Component {
    render(){
        return(
                <div className="btn_wrapper">
                { this.props.isFinished
                  ?
                  <button className="myBtn" onClick={this.props.onResultClick}>けっかをみる</button>                
                  :
                  <button className="myBtn" onClick={this.props.onNextClick} >つぎのもんだい</button>
                }
            </div>
        );
    }
}

// // 「リトライ」
class RetryBtn extends React.Component {
    render(){
        return(
                <div className="btn_wrapper">
                	<button className="myBtn" onClick={this.props.onClick}>リトライ</button>                
                </div>
        );
    }
}

// 「出題」と「PC判定」の一致結果
class PredictResult extends React.Component {
    render(){
        // console.log("PredictResult:", this.props);

        if (this.props.isGoodScore){
            // 背景画像を設定する
            const body = document.getElementById("imgWrapper");
            body.style.backgroundImage = `url(${dojo_appare})`;
        }

        return(<div></div>);
    }
}

// 判定結果
class JudgeResult extends React.Component {
    render(){
        let myWord = KatakanaName[this.props.myPredict]
        return(
                <div>
                { this.props.isError
                  ?
                  <ErrorResult />
                  :
                  <div className="predict_info_wrapper">
                  <p className="predict_elem">コンピュータは きみの じ を</p>
                  <p className="predict_elem"><span className="predictResult">「{myWord}」</span>と はんていしたぞ！</p>
                  <PredictResult isGoodScore={this.props.isGoodScore}/>
                  </div>
                }
            </div>
        );
    }
}

// サーバアクセスエラー
class ErrorResult extends React.Component {
    render(){
        // console.log("ErrorResult called!!!");
        return(
                <b>コンピュータのちょうしがわるいようだ。はんていできない。</b>
        );
    }
}


// 判定結果表示エリア
class JudgePanel extends React.Component {
    render(){
        return (
                <div>                
                { this.props.myPredict
                  ?
                  <JudgeResult
                  myPredict={this.props.myPredict}
                  isGoodScore={this.props.isGoodScore}
                  isError={this.props.isError}
                  />
                  :
                  null
                }
                </div>
        );
    }
}

export class ThirdPage extends React.Component {

    constructor(props) {
        super(props);
        // console.log("Third props:", this.props);
        this.state = {
            base64 : this.props.location.state.base64,
            targetQ : this.props.location.state.targetQ,
            currentQuestionIdx : this.props.location.state.currentQuestionIdx,
            getPoint : this.props.location.state.getPoint,
            isError : false,
            isFinished : false,
            isPredictMatch : false,
            myHist : this.props.location.state.myHist,
            curQ : KatakanaName[this.props.location.state.targetQ[this.props.location.state.currentQuestionIdx]],
            myPredictHist : this.props.location.state.myPredictHist,
            myScoreHist : this.props.location.state.myScoreHist,
        };

            
        // console.log("THIRD props:", this.props);
        console.log("THIRD state:", this.state.myScoreHist);

        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleResultClick = this.handleResultClick.bind(this);
        this.handleRetryClick = this.handleRetryClick.bind(this);

        // canvasを非表示にする
        const can = document.querySelector('canvas');
        can.style.display = "none";

        // 背景画像を設定する
        const body = document.querySelector('body');
        body.style.backgroundImage = '';


    }  // end of constructor

    // 「つぎのもんだいへ」ボタン押下イベント
    handleNextClick() {

        this.props.history.push({
            pathname: '/secondpage',
            state: {
                targetQ : this.state.targetQ,
                currentQuestionIdx : this.state.currentQuestionIdx,
                getPoint : this.state.getPoint,
                myHist : this.state.myHist,
                myPredictHist : this.state.myPredictHist,
                myScoreHist : this.state.myScoreHist,
            }
        });        
    }

    // 「けっかをみる」ボタン押下イベント
    handleResultClick() {

        this.props.history.push({
            pathname: '/fourthpage',
            state: {
                targetQ : this.state.targetQ,
                currentQuestionIdx : this.state.currentQuestionIdx,
                getPoint : this.state.getPoint,
                myHist : this.state.myHist,
                myPredictHist : this.state.myPredictHist,
                myScoreHist : this.state.myScoreHist,
            }
        });        
    }

    // 「リトライ」ボタン押下イベント
    handleRetryClick() {
        window.location.reload();
        // this.forceUpdate();
    }

    // 更新時
    componentDidUpdate() {
        console.log("Third didupdate called!!!");
    }

    render() {
        let isGoodScore = false;
        if(this.state.myScore >= 9999){
            isGoodScore = true;
        }
        return (
                <div>
                <CurQ curQ={this.state.curQ} />
                <JudgePanel
                            myPredict={this.state.jsonData}
                            isGoodScore={isGoodScore}
                            isError={this.state.isError}
                />
                <div id="MyProgress2" className="info_wrapper"></div>
                <div id="imgWrapper">
                <MyImg imgSrc={this.state.base64} />
                <div id="MyPredict2"></div>
                <div id="MyHistory"></div>
                </div>
                <div id="MyJudgeScore"></div>
                <div id="MyNextBtn"></div>
                </div>
        );
    }

    // after render
    componentDidMount(){

        // 背景画像を設定する
        const body = document.getElementById("imgWrapper");
        body.style.backgroundImage = `url(${dojo_close})`;
        body.style.backgroundRepeat = "no-repeat";
        body.style.backgroundPosition = "center center";
        body.style.height = "200px"

        // Progressバー
        this.subRoot1 = document.createElement('div');
        document.getElementById('MyProgress2').appendChild(this.subRoot1);
        ReactDOM.render(<progress />, this.subRoot1);
        // ReactDOM.render(<MyProgress />, this.subRoot1);

        // 「PC判定結果」
        this.subRoot2 = document.createElement('div');
        document.getElementById('MyPredict2').appendChild(this.subRoot2);

        // 「つぎのもんだい」ボタン
        this.subRoot3 = document.createElement('div');
        document.getElementById('MyNextBtn').appendChild(this.subRoot3);

        // // 「リトライ」ボタン
        this.subRoot4 = document.createElement('div');
        document.getElementById('MyNextBtn').appendChild(this.subRoot4);

        // 判定スコア
        this.subRoot5 = document.createElement('div');  // 履歴
        document.getElementById('MyJudgeScore').appendChild(this.subRoot5);

        // 不要な情報を取り除く
        var base64 = this.state.base64.replace(/^data:image\/png;base64,/, '');

        // サーバへの送信
        // (方法1) 'multipart/form-data'としてPOST
        // var formData = new FormData();
        // formData.append('imgBase64', base64);
        // // 'Content-Type': 'multipart/form-dataとしてPOSTしている

        // (方法2) 'JSON'としてPOST
        var JSONdata = {
            imgBase64: base64,
        };

        // // Timeout用 
        // var controller = new AbortController();

        // fetch('http://localhost:4100/recognize', {
        // package.jsonにproxyの設定を追加
        fetch('https://katakanadojo.tokyo/recognize', {
            mode: 'cors',
            method: 'POST',
//            signal: controller.signal,  // controllerが持つsignalをfetchに渡す

            // (方法1)'multipart/form-data'としてPOST
            // body: formData,

            // (方法2)JSONとしてPOST
            header: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(JSONdata),

            // (ボツ)
            // header: { "Content-Type": "application/octet-stream" },

        })
            .then(checkStatus)
            .then(parseJSON)
            .then((data) => {
                console.log('request succeeded with JSON response', data);

                // 描画イメージの履歴を保存
                let myHist = this.state.myHist.slice();
                myHist.push(this.state.base64);

                // 判定結果を履歴保存
                let myPredictHist = this.state.myPredictHist.slice();
                myPredictHist.push(data['predict']);

                // 「PC判定結果」をstateにセット
                this.setState({
                    jsonData : data['predict'],
                    myPredictHist : myPredictHist,
                    myHist : myHist,
                });

                // 現在の出題（ラベル）を退避
                let targetLabel = this.state.targetQ[this.state.currentQuestionIdx];

                // 「PC判定結果」と「出題」の突き合わせ
                if(this.state.jsonData == targetLabel){
                    this.setState({
                        isPredictMatch : true,
                        getPoint : this.state.getPoint + 1,
                    });
                }

                console.log("Predict Judge:",
                            this.state.jsonData,
                            this.state.targetQ[this.state.currentQuestionIdx],
                            this.state.isPredictMatch,
                            this.state.getPoint,
                           );

                // 全て出題されたか？
                if(this.state.currentQuestionIdx == this.state.targetQ.length-1){
                    this.setState({
                        isFinished : true,
                    });
                }

                // 「出題index」を更新
                this.setState({
                    currentQuestionIdx : this.state.currentQuestionIdx + 1,
                });

                console.log("isFinished:", this.state.isFinished);

                // progressバーをキャンセル
                ReactDOM.unmountComponentAtNode(this.subRoot1);

                // 「次のもんだい」ボタン
                ReactDOM.render(
                        <NextBtn
                    onNextClick={this.handleNextClick}
                    onResultClick={this.handleResultClick}
                    isFinished={this.state.isFinished} />,
                    this.subRoot3
                );

                // 「判定スコア」を表示
                // 「出題」に対する得点
                let scoreArr = data['top_N'];
                let myScore = 0;
                scoreArr.forEach(function(v, i, a){
                    if(v['label'] == targetLabel){
                        myScore = calcScore(v['probability']);
                    }
                });
                this.setState({
                    myScore : myScore,
                });
                // スコアの履歴を保存
                let myScoreHist = this.state.myScoreHist.slice();
                myScoreHist.push(myScore);

                // stateにセット
                this.setState({
                    myScoreHist : myScoreHist,
                });

                ReactDOM.render(
                        <JudgeScore
                    score={data['top_N']} />,
                    this.subRoot5
                );


            }).catch((error) => {
                // console.log('request failed', error);
//                controller.abort();  // fetchキャンセル

                this.setState({
                    jsonData : "",
                    isError : true,
                });

                // progressバーをキャンセル
                ReactDOM.unmountComponentAtNode(this.subRoot1);

                // 「ちょうしがわるい」を表示
                ReactDOM.render(<ErrorResult />, this.subRoot1);

                // // 「リトライ」ボタン
                ReactDOM.render(<RetryBtn onClick={this.handleRetryClick} />, this.subRoot4);

                // console.log("catch called!!!");
            });

    }  // end of componentDidMount

    componentWillUnmount() {
        // アンマウント時はサブツリー除去
        // console.log("componentWillUnmount called!!!");
        ReactDOM.unmountComponentAtNode(this.subRoot1);
        ReactDOM.unmountComponentAtNode(this.subRoot2);
        ReactDOM.unmountComponentAtNode(this.subRoot3);
        ReactDOM.unmountComponentAtNode(this.subRoot4);
        ReactDOM.unmountComponentAtNode(this.subRoot5);
    }


}  // end of class



function checkStatus(response) {
    // console.log("[STATUS]", response.status);
    return response
}

function parseJSON(response) {
    // console.log("[resuponse]", response.json());
    return response.json()
}

