import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import { HashRouter as Router, Switch, Route } from "react-router-dom";

import TopPage from './TopPage'; // --> ok

// import { FirstPage } from './FirstPage';  // --> ok
import SettingPage from './SettingPage'; // --> ok

import { SecondPage } from './SecondPage';  // --> ok
// import SecondPage from './SecondPage'; // --> ng

import { ThirdPage } from './ThirdPage';  // --> ok
import { FourthPage } from './FourthPage';  // --> ok

export default function App() {
    return (
            <Router>
                <div>
                    <Switch>
                        <Route path="/" exact component={TopPage}></Route>
                        <Route path="/settingpage" exact component={SettingPage}></Route>
                        <Route path="/secondpage" exact component={SecondPage}></Route>
                        <Route path="/thirdpage" exact component={ThirdPage}></Route>
                        <Route path="/fourthpage" exact component={FourthPage}></Route>
            			<Route component={TopPage} />
                    </Switch>
                </div>
            </Router>
    );
}
