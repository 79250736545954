import React from 'react';
import dojo from './tatami.png'
import KatakanaName from './KatakanaDict'

import Slider from '@material-ui/core/Slider';

import ShareBoard from './ShareBoard';


function isDummyKey(key){

    if((key === 'YI') ||
       (key === 'YE') ||
       (key === 'WI') ||
       (key === 'WU') ||
       (key === 'WE') || 
       (key === 'N2') ||
       (key === 'N3') ||
       (key === 'N4') ||
       (key === 'N5') ){
        return true;
    }
    return false;
}

// 個々のボタン
function Square(props) {

    // console.log(props.xIsOn)
    // console.log(props.value)
    // let myVal = props.xIsOn ? props.value : '-';
    let myBtnClass = props.xIsOn ? 'square_on' : 'square_off';

  return (
    <button className={myBtnClass} onClick={props.onClick}>
      {props.value}
    </button>
  );
}

class BoardInfo extends React.Component {
  render() {
    return (
            <div><p>{this.props.myText}</p></div>
    );
  }
}
    
class Board extends React.Component {

    renderSquare(c) {
        return (
                <Square
            	value={this.props.squares[c]}
            	onClick={() => this.props.onClick(c)}
            	xIsOn={this.props.xIsOn[c]}
                />
        );
    }

  render() {
    return (
      <div>
        <div className="board-row">
          {this.renderSquare('NN')}
          {this.renderSquare('WA')}
          {this.renderSquare('RA')}
          {this.renderSquare('YA')}
          {this.renderSquare('MA')}
          {this.renderSquare('HA')}
          {this.renderSquare('NA')}
          {this.renderSquare('TA')}
          {this.renderSquare('SA')}
          {this.renderSquare('KA')}
          {this.renderSquare('A')}
        </div>
        <div className="board-row">
          {this.renderSquare('N2')}
          {this.renderSquare('WI')}
          {this.renderSquare('RI')}
          {this.renderSquare('YI')}
          {this.renderSquare('MI')}
          {this.renderSquare('HI')}
          {this.renderSquare('NI')}
          {this.renderSquare('TI')}
          {this.renderSquare('SI')}
          {this.renderSquare('KI')}
          {this.renderSquare('I')}
        </div>
        <div className="board-row">
          {this.renderSquare('N3')}
          {this.renderSquare('WU')}
          {this.renderSquare('RU')}
          {this.renderSquare('YU')}
          {this.renderSquare('MU')}
          {this.renderSquare('HU')}
          {this.renderSquare('NU')}
          {this.renderSquare('TU')}
          {this.renderSquare('SU')}
          {this.renderSquare('KU')}
          {this.renderSquare('U')}
        </div>
        <div className="board-row">
          {this.renderSquare('N4')}
          {this.renderSquare('WE')}
          {this.renderSquare('RE')}
          {this.renderSquare('YE')}
          {this.renderSquare('ME')}
          {this.renderSquare('HE')}
          {this.renderSquare('NE')}
          {this.renderSquare('TE')}
          {this.renderSquare('SE')}
          {this.renderSquare('KE')}
          {this.renderSquare('E')}
        </div>
        <div className="board-row">
          {this.renderSquare('N5')}
          {this.renderSquare('WO')}
          {this.renderSquare('RO')}
          {this.renderSquare('YO')}
          {this.renderSquare('MO')}
          {this.renderSquare('HO')}
          {this.renderSquare('NO')}
          {this.renderSquare('TO')}
          {this.renderSquare('SO')}
          {this.renderSquare('KO')}
          {this.renderSquare('O')}
        </div>
      </div>
    );
  }
}

export class FirstPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            maxQuestionCnt : 3,
            selectedCnt : 0,
            squares        : {},
            xIsOn          : {
                A  : false, I  : false, U  : false, E  : false, O  : false,
                KA : false, KI : false, KU : false, KE : false, KO : false,
                SA : false, SI : false, SU : false, SE : false, SO : false,
                TA : false, TI : false, TU : false, TE : false, TO : false,
                NA : false, NI : false, NU : false, NE : false, NO : false,
                HA : false, HI : false, HU : false, HE : false, HO : false,
                MA : false, MI : false, MU : false, ME : false, MO : false,
                YA : false, YI : false, YU : false, YE : false, YO : false,
                RA : false, RI : false, RU : false, RE : false, RO : false,
                WA : false, WI : false, WU : false, WE : false, WO : false,
                NN : false, N2 : false, N3 : false, N4 : false, N5 : false, 
            },
            squares : KatakanaName,
        };

        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleSliderChange = this.handleSliderChange.bind(this);
        this.handleAllOffClick = this.handleAllOffClick.bind(this);

        // canvasを非表示にする
        const can = document.querySelector('canvas');
        can.style.display = "none";

        // 背景画像を設定する
        const body = document.querySelector('body');
        body.style.backgroundImage = `url(${dojo})`;
        // body.style.backgroundRepeat = "no-repeat";
        // body.style.backgroundSize = "cover";
    }

    // 「ぜんぶ うらにする」ボタン押下イベント
    handleAllOffClick(e) {

        const copyxIsOn = Object.assign({}, this.state.xIsOn);

        Object.keys(copyxIsOn).forEach(function(key){
            copyxIsOn[key] = false;
        });
        this.setState({
            xIsOn : copyxIsOn,
        });
    }

    // 「はじめる」ボタン押下イベント
    handleStartClick(e) {

        let targetArr = [];
        // trueのものだけ抽出
        for(let key of Object.keys(this.state.xIsOn)) {
            if( (this.state.xIsOn[key] === true) && (!isDummyKey(key)) ){
                targetArr.push(key)
            }
        }

        // シャッフルする
        // Fisher-Yatesアルゴリズムを用いる方法
        // https://qiita.com/komaji504/items/62a0f8ea43053e90555a
        for(var i = targetArr.length - 1; i > 0; i--){
            var r = Math.floor(Math.random() * (i + 1));
            var tmp = targetArr[i];
            targetArr[i] = targetArr[r];
            targetArr[r] = tmp;
        }

        let selectedQ = [];
        if(targetArr.length > 0){
            selectedQ = targetArr.slice(0, this.state.maxQuestionCnt);
        }

        // 選択数が出題数より少ない場合は追加する
        if(targetArr.length < this.state.maxQuestionCnt){
            console.log("less than!!!");
            let diffCnt = this.state.maxQuestionCnt - targetArr.length; // 足りない分を求める
            let addArr = [];
            for(let key of Object.keys(this.state.xIsOn)){
                if(!isDummyKey(key)){
                    addArr.push(key);
                }
            }
            for(let i = addArr.length - 1; i > 0; i--){
                let r = Math.floor(Math.random() * (i + 1));
                let tmp = addArr[i];
                addArr[i] = addArr[r];
                addArr[r] = tmp;
            }
            selectedQ =  selectedQ.concat(addArr.slice(0, diffCnt));
        }

        this.state.selectedQ = selectedQ; // setStateを使わない代入でOK???
        console.log("CHECK", this.state.selectedQ, selectedQ);

        // objectからArrayにする
        let targetQ = [];
        for(let key of Object.keys(this.state.selectedQ)) {
            targetQ.push(this.state.selectedQ[key]);
        }
        console.log("targetQ:", targetQ);

        let myHist = [];
        let myPredictHist = [];
        let myScoreHist = [];

        this.props.history.push({
            pathname: '/secondpage',
            state: {
                targetQ : targetQ,
                currentQuestionIdx : 0,
                getPoint : 0,
                myHist : myHist,
                myPredictHist : myPredictHist,
                myScoreHist : myScoreHist,
            }
        });
    }

    // 出題のON/OFF切り替え
    handleClick(c) {

        // ダミーなら抜ける
        if(isDummyKey(c)){return;}

        // すでに上限に達していて、
        // それ以上、ONにしようとしているなら抜ける
        if(this.state.selectedCnt >= this.state.maxQuestionCnt){
            if(this.state.xIsOn[c] === false){
                return;
            }
        }

        const copyxIsOn = Object.assign({}, this.state.xIsOn);
        copyxIsOn[c] = !this.state.xIsOn[c];

        this.setState({
            xIsOn: copyxIsOn,
        });

        // trueのものだけ抽出して数える
        let newCnt = 0;
        for(let key of Object.keys(copyxIsOn)) {
            // console.log('key:', key, copyxIsOn[key]);
            if(copyxIsOn[key] === true){
                newCnt +=1;
            }
        }
        this.setState({
            selectedCnt: newCnt,
        });

    }

    // Slider Changeイベント
    handleSliderChange(e, value) {
        // console.log(value);
        this.setState({
            maxQuestionCnt: value,
        });      

    }

    // FirstPage's render
    render() {
        return (
                <div>

                <div className="info_wrapper">
                <BoardInfo myText="もじ を えらぶ" />
                </div>

                <div className="kokuban-s1">
                <p>{this.state.selectedCnt}</p>
            	</div>

                <div className="board_wrapper">
                <Board squares={this.state.squares} onClick={c => this.handleClick(c)} xIsOn={this.state.xIsOn} />
                </div>

            {/*
                <div className="btn_wrapper">
                <button className="myBtn" onClick={(e) => this.handleAllOffClick(e)} >やりなおす</button>
                </div>
             */}

                <div className="btn_wrapper">
                <button className="myBtn" onClick={(e) => this.handleStartClick(e)} >はじめる</button>
                </div>


            {/*
                <div className="info_wrapper">
                <BoardInfo myText="もんだい の かず を かえる" />
                </div>

                <div className="slider_wrapper">
                <Slider
            defaultValue={this.state.maxQuestionCnt}
            valueLabelDisplay="auto"
            onChange={(e, val) => this.handleSliderChange(e, val)}
            marks={true}
            step={1}
            min={1}
            max={5}
                />
                </div>
             */}

            {/*
               <div className="info_wrapper">
                <BoardInfo myText="ほかの ひとに おしえる" />
                </div>

                <div className="sns_wrapper">
                    <ShareBoard />
                </div>
             */}

                </div>

        );
    }

}

export default FirstPage
