import React from "react";
import ReactDOM from 'react-dom';
// import dojo2 from './ichimatsu.jpeg'
// import dojo2 from './dojo2_close.png'
import dojo2 from './tatami.png'
import KatakanaName from './KatakanaDict'
import HiraganaName from './HiraganaDict'

// 「Nもんめ」
class QeustionPanel extends React.Component {
    render(){
        let currentIdx = this.props.current + 1;
        let myWord = HiraganaName[this.props.myWord];
        return (
                <div>
                {/* <b>もんだい「{katakanaName[this.state.selectedQ[this.state.currentQuestionIdx]]}」</b> */}
                <b>{this.props.total}もんちゅう {currentIdx}もんめ もんだい カタカナの「{myWord}」</b>
                </div>
        );
    }
}

export class SecondPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            targetQ : this.props.location.state.targetQ,
            currentQuestionIdx : this.props.location.state.currentQuestionIdx,
            getPoint : this.props.location.state.getPoint,
            canvas : document.getElementById("can"),
            myHist : this.props.location.state.myHist,
            myPredictHist : this.props.location.state.myPredictHist,
            myScoreHist : this.props.location.state.myScoreHist,
        };

        console.log("SECOND props:", this.props);

        // ハンドル関数を登録
        this.handleJudgeClick = this.handleJudgeClick.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);

        // canvasを表示する
        this.state.canvas.style.display = "block";

        // 背景画像を設定する
        const body = document.querySelector('body');
        body.style.backgroundImage = `url(${dojo2})`;

    }  // end of constructor

    // 「はんてい」ボタン押下イベント
    handleJudgeClick() {

        var can = document.querySelector('canvas');

        // console.log("handleJudgeClick clicked!!!", this.state.currentQuestionIdx)

        // 次画面へ遷移
        this.props.history.push({
            pathname: '/thirdpage',
            state: {
                base64 : can.toDataURL('image/png'),
                targetQ : this.state.targetQ, 
                currentQuestionIdx : this.state.currentQuestionIdx,
                getPoint : this.state.getPoint,
                myHist : this.state.myHist,
                myPredictHist : this.state.myPredictHist,
                myScoreHist : this.state.myScoreHist,
            }
        });        
    }

    // 「けす」ボタン押下イベント
    handleClearClick(){

        const can = document.querySelector('canvas');
        const ct = can.getContext("2d");
        ct.fillStyle="rgb(255, 255, 255)";
        ct.fillRect(0, 0, can.getBoundingClientRect().width, can.getBoundingClientRect().height);

        clearCan();
        // drawHorizontalLine();
        // drawVerticalLine();

    }

    // Sencondpage's render
    render() {
        return (
                <div>
                <div className="info_wrapper">                
                <QeustionPanel myWord={this.state.targetQ[this.state.currentQuestionIdx]} total={this.state.targetQ.length} current={this.state.currentQuestionIdx} />
                </div>
                <div id="myClearBtn"></div>
                </div>
        );
    }

    // マウント時(after render)
    componentDidMount(){

        // canvasをリセットして罫線を引く
        clearCan();

        this.subRoot1 = document.createElement('div');  // 「はんていする」ボタン
        this.subRoot2 = document.createElement('div');  // 「けす」ボタン

        var candiv = document.getElementById('candiv');
        var parentDiv = candiv.parentNode;

        // canvasの前に追加
        // parentDiv.insertBefore(this.subRoot2, candiv.previousElementSibling);

        // canvasの後ろに追加
        parentDiv.insertBefore(this.subRoot1, candiv.nextSibling);

        // ReactDOM.render(<SubTree1 {...this.state} />, this.subRoot1);
        ReactDOM.render( <JudgeBtn onClick={this.handleJudgeClick} />, this.subRoot1);

        // ReactDOM.render( <ClearBtn onClick={this.handleClearClick} />, this.subRoot2);
        ReactDOM.render(<ClearBtn onClick={this.handleClearClick} />, document.getElementById('myClearBtn'));
    }

    componentDidUpdate() {
        // 更新時にはサブツリーも更新
        // ReactDOM.render(<SubTree1 {...this.state} />, this.subRoot1);
        // ReactDOM.render(<SubTree2 {...this.state} />, this.subRoot2);
    }

    componentWillUnmount() {
        // アンマウント時はサブツリー除去
        // console.log("componentWillUnmount called!!!");
        ReactDOM.unmountComponentAtNode(this.subRoot1);
        ReactDOM.unmountComponentAtNode(this.subRoot2);
    }
}

// 「はんていする」ボタン
class JudgeBtn extends React.Component {

    render() {
        return (
                <div className="btn_wrapper">
                <button className="myBtn" onClick={this.props.onClick}>はんていする</button>
                </div>
        );
    }
}

// 「けす」ボタン
class ClearBtn extends React.Component {

    render() {
        return (
                <div className="btn_wrapper">
                <button className="myBtn" onClick={this.props.onClick}>けす</button>
                </div>
        );
    }
}


// 横線を引く
var drawHorizontalLine = function() {

    var cs = document.getElementById('can'),
        ctx = cs.getContext('2d'),
        csWidth = cs.width,
        csHeight = cs.height,
        center = {
            x: csWidth / 2,
            y: csHeight / 2
        };
    ctx.fillStyle = 'rgba(234,234,234,1)';
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.moveTo(0, center.y);
    for (var i = 0; i < csWidth; i++) {
        if(i%9==0) ctx.fillRect(i, center.y, 3,3);
    }   
    ctx.closePath();
    ctx.stroke();

};

// 縦線を引く
var drawVerticalLine = function() {

    var cs = document.getElementById('can'),
        ctx = cs.getContext('2d'),
        csWidth = cs.width,
        csHeight = cs.height,
        center = {
            x: csWidth / 2,
            y: csHeight / 2
        };
    ctx.fillStyle = 'rgba(234,234,234,1)';
    ctx.lineWidth = 1;

    ctx.beginPath();
    ctx.moveTo(center.x, 0);
    for (var j = 0; j < csHeight; j++) {
        if(j%9==0) ctx.fillRect(center.x, j, 3,3);
    }
    ctx.closePath();
    ctx.stroke();

};

function clearCan(){
    
    var can = document.getElementById('can');
    var ct = can.getContext('2d');

    ct.fillStyle="rgb(255,255,255)";
    ct.fillRect(0, 0, can.getBoundingClientRect().width, can.getBoundingClientRect().height);

    drawHorizontalLine();
    drawVerticalLine();

}
