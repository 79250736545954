import React from 'react';
import dojo from './tatami.png'
import my_logo from './top_logo.png'
import KatakanaName from './KatakanaDict'

const LogoComponent = () =>{
  return <img src={my_logo} />
}

export class TopPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            maxQuestionCnt : 3,
        };

        // 
        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleRankDispClick = this.handleRankDispClick.bind(this);
        this.handleSettingClick = this.handleSettingClick.bind(this);
        
        // canvasを非表示にする
        const can = document.querySelector('canvas');
        can.style.display = "none";

        // 背景画像を設定する
        const body = document.querySelector('body');
        body.style.backgroundImage = `url(${dojo})`;
        // body.style.backgroundRepeat = "no-repeat";
        // body.style.backgroundSize = "cover";

    }

    // 「ちょうせんする」ボタン押下イベント
    handleStartClick(e) {

        // objectからArrayにする
        let targetQ = [];
        targetQ.push('SI');
        targetQ.push('TU');
        targetQ.push('NN');
        console.log("targetQ:", targetQ);

        // シャッフルする
        // Fisher-Yatesアルゴリズムを用いる方法
        // https://qiita.com/komaji504/items/62a0f8ea43053e90555a
        for(var i = targetQ.length - 1; i > 0; i--){
            var r = Math.floor(Math.random() * (i + 1));
            var tmp = targetQ[i];
            targetQ[i] = targetQ[r];
            targetQ[r] = tmp;
        }

        let myHist = [];
        let myPredictHist = [];
        let myScoreHist = [];

        this.props.history.push({
            pathname: '/secondpage',
            state: {
                targetQ : targetQ,
                currentQuestionIdx : 0,
                getPoint : 0,
                myHist : myHist,
                myPredictHist : myPredictHist,
                myScoreHist : myScoreHist,
            }
        });
    }

    // 「ランキング」ボタン押下イベント
    handleRankDispClick(e) {
        // ファイルを読み込んで表示する
        
    }

    // 「れんしゅうする」ボタン押下イベント
    handleSettingClick(e) {
        this.props.history.push({
            pathname: '/settingpage',
            state: {
            }
        });
    }

    // TopPage's render
    render() {
        return (
                <div>

                <div className="kanban_wrapper">
                <h1>カタカナ道場</h1>
                <p>手書き文字認識</p>
                <LogoComponent />
            	</div>


                <div className="btn_wrapper">
                <button className="myBtn" onClick={(e) => this.handleStartClick(e)} >ちょうせん する</button>
                </div>

            {/*
                <div className="btn_wrapper">
                <button className="myBtn" onClick={(e) => this.handleRankDispClick(e)} >ランキング</button>
                </div>
             */}

                <div className="btn_wrapper">
                <button className="myBtn" onClick={(e) => this.handleSettingClick(e)} >れんしゅう する</button>
                </div>

                </div>

        );
    }

}

export default TopPage
