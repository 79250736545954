const KatakanaName = {
    A  : 'ア', I  : 'イ', U  : 'ウ', E  : 'エ', O  : 'オ',
    KA : 'カ', KI : 'キ', KU : 'ク', KE : 'ケ', KO : 'コ',
    SA : 'サ', SI : 'シ', SU : 'ス', SE : 'セ', SO : 'ソ',
    TA : 'タ', TI : 'チ', TU : 'ツ', TE : 'テ', TO : 'ト',
    NA : 'ナ', NI : 'ニ', NU : 'ヌ', NE : 'ネ', NO : 'ノ',
    HA : 'ハ', HI : 'ヒ', HU : 'フ', HE : 'ヘ', HO : 'ホ',
    MA : 'マ', MI : 'ミ', MU : 'ム', ME : 'メ', MO : 'モ',
    YA : 'ヤ', YI : '-',  YU : 'ユ', YE : '-',  YO : 'ヨ',
    RA : 'ラ', RI : 'リ', RU : 'ル', RE : 'レ', RO : 'ロ',
    WA : 'ワ', WI : '-',  WU : '-',  WE : '-',  WO : 'ヲ',
    NN : 'ン', N2 : '-',  N3 : '-',  N4 : '-',  N5 : '-', 
};

export default KatakanaName
