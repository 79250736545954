import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import {
    EmailShareButton,
    LineShareButton,
    FacebookShareButton,
    TwitterShareButton,
} from "react-share";

import {
    EmailIcon,
    LineIcon,
    FacebookIcon,
    TwitterIcon,
} from "react-share";


class ShareBoard extends React.Component {

    render(){
        
        // Material-ui関連
        const { classes } = this.props;
    
        // シェアボタン用
        const shareUrl = "https://katakanadojo.tokyo";
        const title = "カタカナ道場";

        return (

            <div className={classes.snsShareButtonArea}>

            <EmailShareButton
                url={shareUrl}
                subject={title}
                body="body"
                className={classes.snsShareButton}>
                <EmailIcon size={36} round />
            </EmailShareButton>

            <LineShareButton
                url={shareUrl}
                title={title}
                className={classes.snsShareButton}>
                <LineIcon size={36} round />
            </LineShareButton>

            <TwitterShareButton
                url={shareUrl}
                title={title}
                className={classes.snsShareButton}>
                <TwitterIcon size={36} round />
            </TwitterShareButton>

            <FacebookShareButton
                url={shareUrl}
                quote={title}
                className={classes.snsShareButton}>
                <FacebookIcon size={36} round />
            </FacebookShareButton>


            </div>

        );  // end for return
    }       // end of render
}           // end of class 

// スタイル
const styles = theme => ({
    root: {
    },
    snsShareButtonArea: {
        // padding: 10,
        display: 'flex',
        justifyContent: 'space-around',
    },
    snsShareButton: {
    },
    copyToCripboardButton: {
        
    },
});

    
// Material-ui関連
ShareBoard.propTypes = {
    classes: PropTypes.object.isRequired,
    // theme: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShareBoard);
