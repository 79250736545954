import React from "react";
import ReactDOM from 'react-dom';
import  KatakanaName from './KatakanaDict'
// import appare from './dojo_appare.jpg'
import dojo from './tatami.png'
import dojo_close from './dojo2_close.png'
import dojo_appare from './dojo2_appare.png'

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import Demo from './Demo';
import ShareBoard from './ShareBoard';

import {Grid} from "@material-ui/core"


class DemoWrap extends React.Component {

    render(){
        // console.log("DemoWrap:", this.props);

        return(
                <Grid container justify="center">
	                <Grid item>
    	            {this.props.hist.map((item, i) => {
        	            return <Demo
                        key={i}
                        myImg={this.props.hist[i]}
                        targetQ={KatakanaName[this.props.targetQ[i]]}
                        score={this.props.myScore[i]}
                        qIdx={i+1} /> 
            	    })}
            		</Grid>
                </Grid>
        );
    }
}

class BoardInfo extends React.Component {
  render() {
    return (
            <div><p>{this.props.myText}</p></div>
    );
  }
}


// 「さいしょから」
class NextBtn extends React.Component {
    render(){
        return(
                <div className="btn_wrapper">
                <button className="myBtn" onClick={this.props.onClick}>さいしょから</button>
                </div>
        );
    }
}

// ポイント結果表示エリア
class PointPanel extends React.Component {
    render(){
        let totalScore = this.props.targetQ.length * 10000;
        let myScore  = 0;
        let scoreArray = this.props.myScore;
        scoreArray.forEach(function(elm) {
            myScore += elm;
        });

        return (
                <div className="score_wrapper">
                ごうけい とくてん <font color='orangered' size='6'>{myScore}</font> / {totalScore} 
                </div>
        );
    }
}

export class FourthPage extends React.Component {

    constructor(props) {
        super(props);
        // console.log("this.props:", this.props);
        this.state = {
            base64 : this.props.location.state.base64,
            targetQ : this.props.location.state.targetQ,
            currentQuestionIdx : this.props.location.state.currentQuestionIdx,
            getPoint : this.props.location.state.getPoint,
            isError : false,
            isFinished : false,
            isPredictMatch : false,
            myHist : this.props.location.state.myHist,
            myPredictHist : this.props.location.state.myPredictHist,
            myScoreHist : this.props.location.state.myScoreHist,
        };
            
        this.handleStartClick = this.handleStartClick.bind(this);

        // canvasを非表示にする
        const can = document.querySelector('canvas');
        can.style.display = "none";

        // 背景画像を設定する
        const body = document.querySelector('body');
        body.style.backgroundImage = `url(${dojo})`;

    }  // end of constructor

    // 「さいしょから」ボタン押下イベント
    handleStartClick() {

        this.props.history.push({
            pathname: '/',
            state: {
            }
        });        
    }

    // 更新時
    componentDidUpdate() {
    }

    render() {
        return (
                <div>
                <DemoWrap hist={this.state.myHist} targetQ={this.state.targetQ} myPred={this.state.myPredictHist} myScore={this.state.myScoreHist}/>
                <PointPanel targetQ={this.state.targetQ} myScore={this.state.myScoreHist} />
                <NextBtn onClick={this.handleStartClick} />

            {/*
                <div className="info_wrapper">
                <BoardInfo myText="ほかの ひとに おしえる" />
                </div>
                <div className="sns_wrapper">
                    <ShareBoard />
                </div>
             */}
                </div>
        );
    }

    // after render
    componentDidMount(){

    }  // end of componentDidMount

    componentWillUnmount() {
    }

}  // end of class
